import { defineStore } from "pinia";
import { useRuntimeConfig } from "#app";
// import { useBrandColorStore } from "./brand-color";

export const useInterfaceStore = defineStore("interface", {
  state: () => ({
    padless: false, // <--- used on our editor websites/[website]/content/[id]/file to remove padding
    sidemenu: true,
    modal: {
      domain: false,
    },
  }),
});
